.App {
  background: radial-gradient(circle, #1C252E 0%, #101214 100%);
  color:white;
}

body, html {
  position: relative;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue",
      "Lucida Grande", "Segoe UI";
  overflow-x: hidden;
}

.vertical-line {
    border-right: 1px solid #292E33;
    height: 100%;
    position: absolute;
    flex: 0 0 13%%;
    max-width: 13%;
    min-width: 13%;
    top:0;
    left:15px;
    color: black;
}
