@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }
}
@media (min-width: 700px) {
  .hide-md {
    display: none;
  }
}

.side-margin {
  flex: 0 0 13%;
  max-width: 13%;
  min-width: 13%;
}
.side-badge {
  min-width: 20px;
  height: auto;
  margin-left: 50%;
}
.side-badge-sm {
  width: 56px;
  margin-left: 5%;
}
.badge-2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

/* Header */
@media (min-width: 700px) {
  .header {
    padding-top: 5%;
  }
}
@media (max-width: 700px) {
  .header {
    padding-top: 15%;
    /* padding-left: 5%; */
  }
}

.logo-badge {
}
.button-ios {
  /* height: 48px; */
  /* width: 161px; */
}
.button-google {
  /* height: 48px; */
  /* width: 161px; */
}

/* Hero */
.hero {
  position: relative;
  margin-top: 5%;
}

.pattern-hero {
  position: absolute;
  top: 0px;
  left: 8%;
  top: 7%;
  left: 20%;
}
@media only screen and (min-width: 700px) {
  .iphone {
    margin-top: 3%;
    width: 70%;
  }
  .pattern-hero {
    position: absolute;
    top: 0px;
    left: 8%;
    top: 7%;
    /* left: 20%; */
  }
  .image-copy {
    margin-top: 5%;
    margin-left: 5px;
    padding: 0px !important;
  }

  .app-store {
    width: 300px;

    margin-top: 5%;
    padding-top: 2%;
  }

  .btn.notify {
    width: 60%;
    height: 6%;
    font-size: 1.5vw;
    font-weight: bold;
    border-radius: 57.3px;
    background-color: #f7d707;
    margin-top: 12%;
    margin-left: 10px;
    padding-top: 2%;
  }
}
@media only screen and (max-width: 700px) {
  .app-store {
    width: 180px;
    height: 150px;
    margin-top: 5%;
    margin-right: 22%;
    margin-left: 22%;
  }
  .iphone {
    margin-top: 15%;
    /* width: 70%; */
  }
  .image-copy {
    margin-top: 15%;
    /* margin-left: 5%;
  margin-right: 5%; */
  }

  .btn.notify {
    width: 100%;
    height: 11%;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    border-radius: 57.3px;
    background-color: #f7d707;
    margin-top: 15%;
    display: grid;
    align-items: center;
  }
}
.phone-hero-front {
  position: absolute;
  height: 800px;
  margin-left: -20%;
  margin-top: 10%;

  z-index: 2;
}
.phone-hero-back {
  height: 800px;
  position: relative;
  z-index: 1;
}

/* Features */

@media (min-width: 700px) {
  .make-better-investme {
    color: #868e95;
    font-size: 6vw;
    font-weight: bold;
    line-height: 5.75vw;
    margin-top: 12%;
  }
  .feature-text {
    margin-left: 8%;
  }
  .features {
    margin-top: 5%;
    margin-left: -4%;
  }

  .feature-bg {
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0.5% 0.5%;
    padding: 5% 0% 5% 5% !important;
  }

  .feature-text-bold {
    color: #ffffff;
    width: 52%;
    font-size: 2.5vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 2.75vw;
  }

  .feature-text-slim {
    color: #868e95;
    width: 78%;
    font-size: 1.5vw;
    margin-top: 1vw;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2vw;
  }
}
@media (max-width: 700px) {
  .make-better-investme {
    color: #868e95;
    /* font-family: "San Francisco Display"; */
    font-size: 56px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 52px;
    margin-bottom: 10%;
    margin-top: 5%;
  }
  .feature-bg {
    background-color: rgba(0, 0, 0, 0.5);
    margin: 2% 0% 2% 0%;
    padding: 5% 0% 5% 0% !important;
  }
  .feature-text {
    margin: 10% 10%;
  }
  .feature-text-bold {
    color: #ffffff;
    width: 95%;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 1.5rem;
  }

  .feature-text-slim {
    color: #868e95;
    width: 100%;
    font-size: 18px;
    margin-top: 1vw;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }

  .image-feature-1 {
    width: 85%;
    margin-left: 10%;
  }
  .image-feature-2 {
    margin-bottom: 15%;
  }
  .image-feature-3 {
    margin-top: 2%;
  }

  .image-feature-4 {
    margin-top: -8%;
  }
  .phone-hero-front {
    position: absolute;
    height: 400px;
    margin-left: -30%;
    margin-top: 10%;

    z-index: 2;
  }
}

@media (min-width: 700px) {
  .image-feature-1 {
    width: 70%;
    margin-top: -11%;
  }

  .image-feature-2 {
    margin-top: 8%;
  }

  .image-feature-3 {
    margin-left: -11%;
    margin-top: 2%;
  }

  .image-feature-4 {
    margin-top: -8%;
  }
}

/* Get Started */
@media (max-width: 700px) {
  .get-started-in-a-few {
    color: #868e95;
    font-size: 56px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 52px;
    width: 100%;
    margin-left: 10px !important;
    margin-top: 5%;
  }

  .we-support-all-major {
    color: #868e95;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 5%;
    margin-left: 10px !important;
  }
  .steps {
    margin-top: 10%;
    text-align: center;
  }
  .step {
    width: 100%;
    margin-top: 15%;
    margin-bottom: 20%;
  }
  .steps-text-size-1 {
    font-size: 96px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 64px;
  }
  .steps-text-size-2 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 50px;
  }

  .steps-text-size-3 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
  }
}
.get-started {
  margin-top: 10%;
}
@media (min-width: 700px) {
  .get-started-in-a-few {
    color: #868e95;
    font-size: 6vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 5vw;
    width: 55%;
    margin-left: 0px !important;
  }
  .we-support-all-major {
    color: #868e95;
    font-size: 1.5vw;
    letter-spacing: 0;
    line-height: 2vw;
    margin-top: 5%;
    margin-left: 0px !important;
  }
  .steps {
    margin-top: 10%;
  }
  .step {
    width: 70%;
  }

  .steps-text-size-1 {
    font-size: 7vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 3vw;
  }
  .steps-text-size-2 {
    font-size: 3vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 5vw;
  }

  .steps-text-size-3 {
    font-size: 2vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 2vw;
  }
}

.one {
  color: #f7d707;
  /* font-family: "San Francisco Display"; */
}
.two {
  color: #f2b200;
  /* font-family: "San Francisco Display"; */
}
.three {
  color: #d17000;
  /* font-family: "San Francisco Display"; */
}

.steps-text {
  color: #868e95;
}

/* Download */
@media (max-width: 700px) {
  .its-free-get-start {
    color: #ffffff;
    /* font-family: "San Francisco Text"; */
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    text-align: center;
    position: absolute;
    z-index: 2;
    margin-top: 7%;
    width: 100%;
  }
  .download-bg {
    position: relative;
    z-index: 1;
    margin-top: 60%;
    padding: 0px !important;
  }
  .notify-button {
    color: black;
    background-color: #f7d707 !important;
    margin-top: 8%;
    width: 60%;
    border-radius: 20px;
  }

  .launch-text {
    color: #868e95;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 5%;
  }

  .email-input {
    width: 100%;
  }
  .notify-message {
    font-size: 2vw;
    font-weight: normal;
  }
  .notify-form {
    width: 78%;
    margin-left: 11%;
  }
  .download {
    background-color: #1d2227;
    padding-top: 20%;
  }
}

@media only screen and (min-width: 700px) {
  .notify-button {
    color: black;
    background-color: #f7d707 !important;
    border-radius: 20px;
    padding: 10px 30px;
    text-align: center;
    width: 150px;
    font-weight: 600;
    font-size: large;
    letter-spacing: 1.5px;
  }
  .its-free-get-start {
    color: #ffffff;
    /* font-family: "San Francisco Text"; */
    font-size: 3vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 3vw;
    text-align: center;
    position: absolute;
    z-index: 2;
    /* margin-left: 30%; */
    margin-top: 7%;
    width: 100%;
  }

  .download-bg {
    position: relative;
    z-index: 1;
    margin-left: 5%;
  }

  .launch-text {
    color: #868e95;
    font-size: 1.5vw;
    font-weight: 400;
    margin-top: 1%;
  }

  .email-input {
    width: 60%;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin-left: 10%;
  }
  .download {
    background-color: #1d2227;
    margin-top: 5%;
  }

  .notify-message {
    font-size: 1.5vw;
    font-weight: normal;
  }
}

.download-btns {
  position: absolute;
  z-index: 2;
  /* width: 40%; */
  margin-top: 10%;
  margin-left: 10% !important;
}

/* About Us */
@media only screen and (min-width: 700px) {
  .about-us {
    margin-top: 10%;
  }
  .about-us-bg {
    position: relative;
    z-index: 1;
    margin-left: 50%;
    margin-top: 10%;
    width: 90%;
  }
  .about-us-text {
    position: absolute;
    z-index: 2;
    margin-left: 15px;
  }
  .made-with {
    color: #868e95;
    font-size: 6vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 5.75vw;
    width: 50%;
  }
  .love {
    color: #f75c5c !important;
  }

  .mission {
    color: #868e95;
    font-size: 1.5vw;
    /* font-weight: 500; */
    letter-spacing: 0;
    line-height: 2vw;
    margin-top: 5%;
    width: 40%;
  }
}

@media only screen and (max-width: 700px) {
  .about-us {
    margin-top: 30%;
  }
  .about-us-bg {
    position: relative;
    z-index: 1;
    margin-top: 100%;
    /* width: 90%; */
  }
  .about-us-text {
    position: absolute;
    z-index: 2;
    margin-top: 5%;
    margin-left: 25px !important;
  }
  .made-with {
    color: #868e95;
    font-size: 56px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 52px;
    width: 92%;
  }
  .love {
    color: #f75c5c !important;
    margin-left: 10px !important;
  }

  .mission {
    color: #868e95;
    font-size: 18px;
    /* font-weight: 500; */
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 10%;
    width: 92%;
  }
}

/* Footer */

@media only screen and (max-width: 700px) {
  .footer-text {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 32px;
    color: #868e95;
  }
  .footer-content {
    margin-top: 15%;
    padding-bottom: 20%;
    padding-left: 6% !important;
    /* border-top: 2px solid white; */
  }

  .footer-icon {
    min-width: 20px;
    height: auto;
  }

  .footer-bg {
    position: relative;
    z-index: 1;
  }

  .line {
    height: 4px;
    background-color: #adb5bd;
    margin-top: -12%;
  }

  .social {
    margin-top: 7%;
  }
}

@media only screen and (min-width: 700px) {
  .footer-text {
    font-size: 1vw;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 2vw;
    color: #868e95;
    text-align: center !important;
  }
  .footer-content {
    margin-left: -5%;
    margin-top: 6%;
    padding-bottom: 5%;
    padding-left: 6% !important;
    /* border-top: 2px solid white; */
  }

  .footer-icon {
    min-width: 20px;
    height: auto;
  }

  .footer-bg {
    position: relative;
    z-index: 1;
    margin-left: 50.2%;
    width: 90%;
  }

  .line {
    height: 4px;
    background-color: #adb5bd;
    margin-top: -7.5%;
    margin-left: -9%;
  }
}

a {
  color: #868e95;
}
a:hover {
  color: #f75c5c;
}

@media only screen and (max-width: 700px) {
  a {
    font-size: 3.5vw;
    margin-top: 1vw;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2vw;
  }
}
